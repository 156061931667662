import React, { useEffect, useRef, useState } from "react";
import {
  Hero,
  Enroll,
  Explore,
  VideoRow,
  ExtraCurricular,
  QuestionAnswerVideo,
} from "./components";
import ConfigurationContext from "contexts/ConfigurationContext";
import apiRequest from "api/apiRequest";
import { Carousel } from "../../components";
import urls from "../../api/urls";
import { ScriptPages } from "../../constants/scriptsPage";
import { CraftJsonRenderer } from "../../components/PageBuilder";

export const ManageHome = () => {
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);
  const [contents, setContents] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [script, setScript] = useState(null);
  const scriptRef = useRef(null);

  useEffect(() => {
    if (script) {
      setTimeout(() => {
        const scriptTag = document.createElement("script");
        scriptTag.src = script.script;
        scriptTag.async = true;

        if (scriptRef.current) {
          scriptRef?.current?.appendChild(scriptTag);
        }
      }, 1000);
    }
  }, [script, script?.script]);

  useEffect(() => {
    const fetch = async () => {
      const response = await apiRequest({
        method: "get",
        url: `${urls.scripts}?page=${ScriptPages.home}`,
      });

      setScript(response);
    };
    fetch();
  }, []);

  const loadContent = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "homepage-content",
      });
      setContents(response);
      setLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  const getContent = location => {
    const content = contents.find(content => content.location === location);

    if (content?.source === "editor") {
      return <div dangerouslySetInnerHTML={{ __html: content.content }}></div>;
    }

    if (content?.source === "page-builder") {
      return <CraftJsonRenderer craftJson={content.page_builder_content} />;
    }

    return false;
  };

  if (!loaded) return [];
  return (
    <React.Fragment>
      <Carousel />

      {isFeatureEnabled("home_hero") && <div>{getContent("hero") || <Hero />}</div>}
      {isFeatureEnabled("home_questions") && <div>{getContent("questions") || <VideoRow />}</div>}
      {isFeatureEnabled("home_students_videos") && (
        <div>{getContent("students_videos") || <QuestionAnswerVideo />}</div>
      )}
      {isFeatureEnabled("home_ambassadors") && (
        <div>{getContent("ambassadors") || <ExtraCurricular />}</div>
      )}
      {isFeatureEnabled("home_explore") && <div>{getContent("explore") || <Explore />}</div>}
      {isFeatureEnabled("home_enroll") && <div>{getContent("enroll") || <Enroll />}</div>}
      {script && <div ref={scriptRef} dangerouslySetInnerHTML={{ __html: script.html }} />}
      {/*<SocialMediaPosts />*/}
    </React.Fragment>
  );
};

export default ManageHome;
